import React, { useEffect } from "react";
import PopupOverdue from "./Popups/Popup";
import { useApiHook } from "@bjsdistribution/dms-component-library";
import {
  useCountReminder,
  useOverdueReminder,
  useReminderData,
} from "../store/store";
import { format } from "date-fns";

interface ApiResponse {
  message: string;
  code: number;
  error: string | null;
  error_traceback: string | null;
  data: [];
}

const Reminder = () => {
  const [reminders, setReminders] = useReminderData();
  const [, setOverdue] = useOverdueReminder();
  const [, setCount] = useCountReminder();
  let todayDate = new Date();

  //GET Reminders
  const { data: fetchedData, loading: loading } = useApiHook<{
    data: ApiResponse[];
  }>({
    endpoint: `/tasks/reminder/get/`,
    method: "GET",
    filters: [
      {
        field: "due",
        op: ">=",
        value: `${format(new Date(todayDate), "yyyy-MM-dd")}T00:00:00`,
      },
      {
        field: "due",
        op: "<=",
        value: `${format(new Date(todayDate), "yyyy-MM-dd")}T23:59:59`,
      },
    ],
  });

  useEffect(() => {
    if (fetchedData && fetchedData.data) {
      setCount(fetchedData.data);
      const filteredReminders = fetchedData.data.filter(
        (reminder: any) => reminder?.status_id === 1
      );
      setReminders(filteredReminders);
      setOverdue(filteredReminders);
    }
  }, [fetchedData]);

  return (
    <>
      {"  "}
      {reminders?.length > 0 ? (
        <PopupOverdue reminders={reminders} loading={loading} />
      ) : null}
    </>
  );
};

export default Reminder;
