import React, { useEffect } from "react";
import styled from "styled-components";
import { Profile } from "../assets/icons/IconCaretDown2";
import { useUserDetails } from "../store/store";
import Notifications from "./Notifications";

const StyledMainHeader = styled.div`
  background-color: #fafafa;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #3a363026;
  height: 72px;
  position: relative;
  position: fixed;
  width: 100%;
  z-index: 9999;

  @media (max-width: 768px) {
    height: 56px;
  }
`;

const Logo = styled.div`
  height: 71px;
  position: relative;
  width: 124px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const UserInfo = styled.div`
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  @media (max-width: 868px) {
    right: 25px;
  }
`;

const Flex = styled.div`
  position: relative;
  right: 16px;
  display: grid;
  align-items: center;
`;

const SpanName = styled.div`
  position: relative;
  right: 16px;
  display: grid;
  align-items: center;
`;

const Span = styled.div`
  position: relative;
  right: 16px;
  color: #898989;
  justify-self: end;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const NotificationBox = styled.div`
  display: flex;
  gap: 50px;
`;

function Header() {
  const user = {
    name: "",
   
  };

  const [userDetails] = useUserDetails();

// currency 
const getEnvironment = (): 'us' | 'uk' | 'development' => {
  console.log('project env', process.env.REACT_APP_API_PATH);
  if (process.env.REACT_APP_API_PATH === 'https://us.dms.api.bjshomedelivery.com') {
    return 'us';
  } else if (process.env.REACT_APP_API_PATH === 'https://dms.bjshomedelivery.com') {
    return 'uk'; 
  } else {
    return 'development';
  }
};

const getBaseUrl = (): string => {
  const environment = getEnvironment();
  let baseUrlSymbol = '';
  switch (environment) {
    case 'us':
      baseUrlSymbol = '$';
      break;
    case 'uk':
      baseUrlSymbol = '£';
      break;
    case 'development':
    default:
      baseUrlSymbol = '£'; 
  }

  localStorage.setItem('baseUrlSymbol', baseUrlSymbol);

  return baseUrlSymbol;
};
useEffect(()=>{
  getBaseUrl()
},[getEnvironment])

  return (
    <>
      <StyledMainHeader>  
        <Logo>
          <img alt="BJSLOGO" src="/images/bjslogo.png" />
        </Logo>
        {/* <Search property1={'default'} inputType={''} className={undefined} /> */}
        <UserInfo>
          <NotificationBox>
            <Notifications />
            <Flex>
              <SpanName>{userDetails || user.name}</SpanName>
              {/* <Span>{user.designation}</Span> */}
            </Flex>
          </NotificationBox>
          <Avatar>
            <img alt="User Avatar" src="/images/images.png" />
          </Avatar>
          <Profile className={undefined} />
        </UserInfo>
      </StyledMainHeader>
    </>
  );
}

export default Header;
