import { ClientReturn } from "../../assets/icons/ClientReturn";
import { Debrief } from "../../assets/icons/Debrief/Debrief";
import { Flag } from "../../assets/icons/Flag";
import { Invoice } from "../../assets/icons/Invoice";
import { Offshore } from "../../assets/icons/Offshore";
import { Order } from "../../assets/icons/Order/Order";
import { Product } from "../../assets/icons/Product";
import { Route } from "../../assets/icons/Route";
export const menuData = [
  {
    id: 0,
    name: "Order",
    icon: <Order />,
    url: "/order",
    subMenu: [
      {
        name: "overview",
        url: "/order",
      },
      {
        name: "add",
        url: "/order/add",
      },
      // {
      //   name: "all-reminder",
      //   url: "/order/all-reminder",
      // },
    ],
  },
  {
    id: 1,
    name: "Finance",
    icon: <Debrief />,
    url: "/finance",
    subMenu: [
      {
        name: "Debrief",
        url: "/finance",
      },
      {
        name: "Invoice",
        url: "/finance/invoice",
      },
    ],
  },
  {
    id: 2,
    name: "Product",
    icon: <Product />,
    url: "/product",
    subMenu: [
      {
        name: "overview",
        url: "/product",
      },
      {
        name: "add",
        url: "/product/add",
      },
      {
        name: "category",
        url: "/product/category",
      },
    ],
  },
  {
    id: 3,
    name: "Flag",
    icon: <Flag />,
    url: "/flag",
    subMenu: [
      {
        name: "overview",
        url: "/flag",
      },
    ],
  },
  {
    id: 4,
    name: "Route",
    icon: <Route />,
    url: "/route",
    subMenu: [
      {
        name: "overview",
        url: "/route",
      },
    ],
  },
  // {
  //   id: 5,
  //   name: "Invoice",
  //   icon: <Invoice />,
  //   url: "/invoice",
  //   subMenu: [
  //     {
  //       name: "overview",
  //       url: "/invoice",
  //     },
  //   ],
  // },
  {
    id: 5,
    name: "Offshore",
    icon: <Offshore />,
    url: "/offshore",
    subMenu: [
      {
        name: "overview",
        url: "/offshore",
      },
    ],
  },
  {
    id: 6,
    name: "ClientReturn",
    icon: <ClientReturn />,
    url: "/returnclient",
    subMenu: [
      {
        name: "overview",
        url: "/returnclient",
      },
      // {
      //   name: "return order",
      //   url: "/returnclient/return/",
      // },
      {
        name: "return after order",
        url: "/returnclient/order-return",
      },
    ],
  },
];
