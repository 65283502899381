import React from "react";
import styled from "styled-components";

interface Props {
  className?: any;
}

const StyledIconBoxes = styled.svg`
  & .path {
    opacity: 0.4;
    transition: opacity 0.3s ease;
  }
`;
const ClientReturn = ({ className }: Props): JSX.Element => {
  return (
    <StyledIconBoxes
      className={`icon-boxes ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 32 32"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.504 0.13168C15.6551 0.0453879 15.826 0 16 0C16.174 0 16.3449 0.0453879 16.496 0.13168L23.996 4.41777C24.1491 4.5052 24.2763 4.63153 24.3648 4.78396C24.4533 4.9364 24.4999 5.10952 24.5 5.28578V13.2759L31.496 17.276C31.6485 17.3631 31.7753 17.4888 31.8638 17.6405C31.9523 17.7922 31.9992 17.9645 32 18.14V26.7122C31.9999 26.8885 31.9533 27.0616 31.8648 27.214C31.7763 27.3665 31.6491 27.4928 31.496 27.5802L23.996 31.8663C23.8449 31.9526 23.674 31.998 23.5 31.998C23.326 31.998 23.1551 31.9526 23.004 31.8663L16 27.8662L8.996 31.8683C8.84494 31.9546 8.67397 32 8.5 32C8.32603 32 8.15506 31.9546 8.004 31.8683L0.504 27.5822C0.350946 27.4948 0.223719 27.3685 0.135211 27.216C0.0467025 27.0636 5.68541e-05 26.8905 0 26.7142L0 18.142C5.68541e-05 17.9658 0.0467025 17.7927 0.135211 17.6402C0.223719 17.4878 0.350946 17.3615 0.504 17.274L7.5 13.2759V5.28578C7.50006 5.10952 7.5467 4.9364 7.63521 4.78396C7.72372 4.63153 7.85095 4.5052 8.004 4.41777L15.504 0.13168ZM8.5 15.008L3.016 18.142L8.5 21.2761L13.984 18.142L8.5 15.008ZM15 19.8661L9.5 23.0081V29.2763L15 26.1342V19.8661ZM17 26.1342L22.5 29.2763V23.0081L17 19.8661V26.1342ZM18.016 18.142L23.5 21.2761L28.984 18.142L23.5 15.008L18.016 18.142ZM22.5 13.2759V7.00782L17 10.1519V16.42L22.5 13.2759ZM15 16.42V10.1519L9.5 7.00782V13.2759L15 16.42ZM10.516 5.28578L16 8.41985L21.484 5.28578L16 2.15172L10.516 5.28578ZM30 19.8661L24.5 23.0081V29.2763L30 26.1342V19.8661ZM7.5 29.2763V23.0081L2 19.8661V26.1342L7.5 29.2763Z"
        fill="#3A3630"
      />
    </StyledIconBoxes>
  );
};

export { ClientReturn };
